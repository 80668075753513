export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/rabbitking.png" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              <p>RabbitKing is a meme token looking forward to the Rabbit Year.
                In the Rabbit Year, RabbitKing would be the king of all the memes.</p>
              <p>With rewarding the USDT, holders become more rich if they believe in RabbitKing. <br />
                'If you don't hold, you won't be rich' - CZ
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
